import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { postContactMessage } from "../api";
import Icons from "../assets/Icons";
import config, { selectedProject } from "../config";

const Contact = () => {
  const [nameSurname, setNameSurname] = useState("");
    const [mail, setMail] = useState("");
    const [comment, setComment] = useState("");
  const [phone, setPhone] = useState("");

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);



  const handleCaptcha = (value) => {
    console.log("CAPTCHA Token:", value);
    setIsCaptchaValid(true);

  };


  const saveComment = async()=>{

    
     if (isCaptchaValid) {
         
        const data = { 
          nameSurname,
          mail ,
         phone,
          comment,
        
      }

      if(nameSurname.length>0 && mail.length>0 && comment.length>1)
      {
          const res = await postContactMessage(data);
          if(res.status)
          {
              alert("mesaj gönderme başarılı");

              setComment("");
              setMail("");
              setPhone("");
              setNameSurname("")
   
  
          }
  
      }
      else
      {

          alert("Mesaj Gönderme başarısız" )
      }

  
     }

     else {
         alert('Doğrulama kodu hatalı!');
     }




    }


  return (
    <Container>
      <div className="page-text pt-5 mb-0 page_set">
        <h1 className="page_title">İletişim</h1>
      </div>
      <div className="page-content contact pt-3">
     
        <div className="row">
            <div className="col-lg-5 col-12">
                <div className="contact-box contact-information">
                    <p><span
                        dangerouslySetInnerHTML={{ __html: Icons.home }}
                        className="_icon"/>{config.project.contact.address}</p>
             
                    <p>
                        <span
                        dangerouslySetInnerHTML={{ __html: Icons.phone }}
                        className="_icon"/> <a href="tel:#">{config.project.contact.tel}</a></p>
                    {/* <p><span
                        dangerouslySetInnerHTML={{ __html: Icons.fax }}
                        className="_icon"/> <a href="tel:#">+90 (312) 285 6573</a></p>
                   */}
                    <p> 
                        <span
                        dangerouslySetInnerHTML={{ __html: Icons.email }}
                        className="_icon"/> <a href={"mailto:"+ config.project.contact.mail}>{config.project.contact.mail}</a></p>
                </div>
                <div className="contact-box map_wrapper mt-5">
                    <h3>Harita</h3>
                    <div dangerouslySetInnerHTML={{__html: config.project.contact.map}}>

                    </div>
                </div>
            </div>
            <div className="col-lg-1 col-12"></div>
            <div className="col-lg-6 col-12"> 
                <div className="contact-box contact-form mb-4">
                    <h3>İletişim Formu</h3>
                    <Form method="POST">
                         <Form.Group className="mb-3">
                            <Form.Label>Adınız ve Soyadınız*</Form.Label>
                            <Form.Control type="text"  onChange={e=>setNameSurname(e.target.value)} value={nameSurname} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>E-posta Adresiniz*</Form.Label>
                            <Form.Control type="email" onChange={e=>setMail(e.target.value)}  value={mail} placeholder="isim@ornek.com" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Telefon Numaranız</Form.Label>
                            <Form.Control type="number" onChange={e=>setPhone(e.target.value)}  value={phone} placeholder="05XXXXXXXXX" />
                          </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Mesajınız*</Form.Label>
                                   <Form.Control
                                     as="textarea"
                                     placeholder="Ayrıntılı olarak mesajınızı yazınız."
                                     rows={3}
                                     onChange={e=>setComment(e.target.value)}  value={comment}
                                   />
                        </Form.Group>

                        <ReCAPTCHA
                            sitekey={selectedProject.googleSiteKey}
                            onChange={handleCaptcha}
                          /> 


                        <Button variant="info" onClick={()=>saveComment()} className="text-white mt-3">
                            Gönder 
                        </Button>
                    </Form>
 
                </div>
            </div>
        </div>
        
      </div>
    </Container>
  );
};

export default Contact;
