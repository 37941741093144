
let envConfig = {};
let selectedProject =   {
  id: 1,
  type: 2,
  multiLangStatus: false,

  googleSiteKey:'6LffFtEqAAAAAIq9zdxH-Bq2WJRcxN8qSTmJdRAR',

  name: "Atatürk Ansiklopedisi",
  color1: "#004f9f",
  color2: "#3396d1",
  color3: "#ed1c24",
  color4: "#c9bd68",
 // projectLogo: "/uploads/projectLogo/2.png",
 // mainPageImage: require("./images/atam/2x.png"),
  // './../../images/ttk/atam2.png',

  inst: "ATAM",
  instLongName: "Atatürk Araştırma Merkezi Başkanlığı",
  instUrl:"https://www.atam.gov.tr",
  social: {
    facebook: "https://www.facebook.com/Atam.Baskanlik",
    youtube: "https://www.youtube.com/user/atambaskanlik",
    instagram: "https://www.instagram.com/atambaskanlik/",
    twitter: "https://x.com/AtamBaskanlik",
  },
  contact: {
    address: "Üniversiteler Mah. Toplum Cad. No.: 5 06800 Çankaya/ANKARA",
    mail: "ataturkansiklopedisi@atam.gov.tr",
    tel: "0312 285 65 11 – 285 55 12",
    map: '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6121.620271096461!2d32.741316!3d39.900883!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d347862c84861d%3A0x822c27404f3450bf!2zQXRhdMO8cmsgS8O8bHTDvHIsIERpbCB2ZSBUYXJpaCBZw7xrc2VrIEt1cnVtdQ!5e0!3m2!1sen!2str!4v1723707914855!5m2!1sen!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'  },
  tag: true, 
  whoContributed: true,
  workingGroup: true,

  tagInfo: {
    isbn : "978-975-17-4677-1",
    yayinNo : 487,
    eserAdi : "Atatürk Ansiklopedisi",
    yayimci : "Atatürk Araştırma Merkezi Başkanlığı",
    yayimciMarkaAdi : "Atatürk Kültür Dil ve Tarih Yüksek Kurumu – Atatürk Araştırma Merkezi Başkanlığı",
    yayinlandigiOrtamTuru : "Elektronik Materyal",
    yayinlandigiOrtam:"Elektronik Kapak (Çevrim içi / Web tabanlı)",
    ortamTuru : "http / https",
    erisimeAcilmaTarihi : "29.10.2020",
    erisimAdresi:"www.ataturkansiklopedisi.gov.tr",
    erisimTuru : "Open Access"
  },



};



const devConfig = {

   apiUrl: "http://localhost:3345",
  userAuthApiUrl: "https://testgiris.ayk.gov.tr/api/",
  appUrl:"http://localhost:3005",

};
const testConfig = {
  apiUrl: "https://testsays.ayk.gov.tr/api/",
  userAuthApiUrl: "https://testgiris.ayk.gov.tr/api/",
  appUrl:"https://testsozluk.ttk.gov.tr",

};
const prodConfig = {
  apiUrl: "https://apiko.ayk.gov.tr",
  userAuthApiUrl: "https://giris.ayk.gov.tr/api/",
  appUrl:"https://ataturkansiklopedisi.gov.tr",

};
if (process.env.REACT_APP_ENV === "development") {
  envConfig = devConfig;
} else if (process.env.REACT_APP_ENV === "test") {
  envConfig = testConfig;
} else if (process.env.REACT_APP_ENV === "production") {
  envConfig = prodConfig;
}

export {selectedProject};

const config =  {
  apiUrl: envConfig.apiUrl,
  mainPageUrl: envConfig.mainPageUrl, 
  userAuthApiUrl: envConfig.userAuthApiUrl, 
  appUrl: envConfig.appUrl,


  project:selectedProject,

 
  defaultProfileImageUrl: "/images/user.jpg",

  copright: "Her hakkı saklıdır. Türk Dil Kurumu",
  coprightYear: "2023",



  alphabet : [ 'A', 'B', 'C','Ç', 'D', 'E', 'F', 'G','Ğ', 'H', 'I','İ', 'J', 'K', 'L', 'M', 'N', 'O','Ö', 'P',  'R',  'S','Ş', 'T', 'U','Ü' ,'V', 'Y', 'Z' ],
  numbers: [ 0,1,2,3,4,5,6,7,8,9 ],

  institutions: [
    {
      id: 1,
        inst: "TDK",
      instLongName: "Türk Dil Kurumu",
      instLong: "Türk Dil Kurumu'nun",
      messages: ""
    },
    { 
      id: 2,
      inst: "TTK",
      instLongName: "Türk Tarih Kurumu",
      instLong: "Türk Tarih Kurumu'nun",
      messages: "Evrakı ıslak imzalı olarak (2 Adet) MNG Kargo ile Kurum adına alıcı ödemeli olarak 108814424 müşteri kodu ile gönderebilirsiniz. Alıcı:Serkan KARTAL Tel: 0312 310 23 68 / 134",
    },
    {
      id: 3,
      inst: "ATAM",
      instLongName: "Atatürk Araştırma Merkezi Başkanlığı",
      instLong: "Atatürk Araştırma Merkezi Başkanlığı'nın",
      messages: "",
    },
    {
      id: 4,
      inst: "AKM",
      instLongName: "Atatürk Kültür Merkezi Başkanlığı",
      instLong: "Atatürk Kültür Merkezi Başkanlığı'nın",
      messages: "",
    },
  ],

};

export default config;
